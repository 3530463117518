/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Allura&family=Fredericka+the+Great&display=swap'); */
.App {
  text-align: center;
  font-family: 'Josefin Sans', sans-serif;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  /* background: radial-gradient(
    circle,
    rgba(223, 25, 149, 0.18000720796130953) 54%,
    rgba(255, 199, 44, 0.07356463093049714) 100%
  ); */
  /* background: rgb(16,24,32); */
  /* background: linear-gradient(
    180deg,
    rgba(16, 24, 32, 0.42090356650472693) 54%,
    rgba(16, 24, 32, 0.059559028689600835) 100%
  ); */
  min-height: 100vh;
}
h1 {
  font: 72px/72px Fredericka the Great, cursive;
  text-align: center;
  text-shadow: 3px 3px rgba(0, 0, 0, 0.1);
}
h2 {
  font: 40px/42px Gabriela, Georgia, serif;
  text-align: center;
  text-shadow: 3px 3px rgba(0, 0, 0, 0.1);
}

input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* burger MENU styles*/

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: absolute;
  width: 30px;
  height: 20px;
  right: 0px;
  top: 0px;
}

.bm-burger-bars {
  background: #001428;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #001428;
}

.bm-menu {
  background: #f0f8ff;
  padding: 2.5em 10px;
  width: 100%;
}

.bm-morph-shape {
  fill: #eff8fe;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
}
.bm-menu-wrap {
  left: 0;
  top: 0;
}

/* burger MENU styles -  END*/

.Toastify__progress-bar--info {
  background-color: orange !important;
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  background-color: #1f77b4 !important;
}

@media screen and (max-width: 526px) {
  h1 {
    font: 60px/60px Fredericka the Great, cursive;
  }
}

@media screen and (max-width: 454px) {
  h1 {
    font: 50px/55px Fredericka the Great, cursive;
  }
}

@media screen and (max-width: 371px) {
  h1 {
    font: 45px/50px Fredericka the Great, cursive;
  }
}
